import debounce from '~helper/debounce';

const $header = document.querySelector('.header');
const headerHeight = $header.offsetHeight;

export default () => {
  window.addEventListener(
    'scroll',
    debounce(() => {
      const scrollOffset = window.pageYOffset;

      if (scrollOffset >= headerHeight) {
        $header.classList.add('header--scrolled');
      } else {
        $header.classList.remove('header--scrolled');
      }
    })
  );
};
