import { tns } from 'tiny-slider/src/tiny-slider';

export default () => {
  const $wrappers = document.querySelectorAll('.js-story__images');

  if ($wrappers) {
    $wrappers.forEach($wrapper => {
      const $slider = $wrapper.querySelector('.story__imageslider');
      const $controls = $wrapper.querySelector('.story__imageslider-controls');
      const config = {
        controls: true,
        controlsContainer: $controls,
        container: $slider,
        items: 1,
        slideBy: 1,
        loop: true,
        nav: false,
      };

      tns(config);
    });
  }
};
