import debounce from '~helper/debounce';
import mediaquery from '~helper/mediaquery';

const $site = document.documentElement;
const $logo = document.querySelector('.logo');
const $small = document.querySelector('.logo__image--small');
const $normal = document.querySelector('.logo__image--normal');
const $large = document.querySelector('.logo__image--large');
const $intro = document.querySelector('.intro');

const show = $img => {
  $small.setAttribute('aria-hidden', 'true');
  $normal.setAttribute('aria-hidden', 'true');
  $large.setAttribute('aria-hidden', 'true');
  $img.setAttribute('aria-hidden', 'false');
};

export default () => {
  if ($site.classList.contains('site--home')) {
    show($large);

    window.addEventListener(
      'scroll',
      debounce(() => {
        if (mediaquery('m')) {
          // when scrolling down, we resize the logo, and switch
          // to the normal logo when half size is scrolled and if
          // we are scrolled further than intro, we switch to small
          const introHeight = $intro.offsetHeight;
          const scrollOffset = window.pageYOffset;

          if (scrollOffset > 0 && scrollOffset <= introHeight) {
            const scaleFactor = (introHeight - scrollOffset) / introHeight;
            $logo.setAttribute('style', `transform: translateX(-50%) scale(${scaleFactor});`);
          }

          if (scrollOffset >= introHeight * 0.75) {
            show($small);
            $logo.classList.add('logo--small');
            $logo.classList.remove('logo--normal');
            $logo.classList.remove('logo--large');
          } else if (scrollOffset >= introHeight * 0.25) {
            show($normal);
            $logo.classList.remove('logo--small');
            $logo.classList.add('logo--normal');
            $logo.classList.remove('logo--large');
          } else {
            show($large);
            $logo.classList.remove('logo--small');
            $logo.classList.remove('logo--normal');
            $logo.classList.add('logo--large');
          }
        }
      })
    );
  } else {
    $logo.classList.add('logo--small');
    show($small);
  }
};
